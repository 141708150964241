import React from "react";

const Avatar = ({ thumbLogo }) => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      {thumbLogo ? (
        <img
          alt="Chatbot logo"
          className="react-chatbot-kit-chat-bot-avatar-img"
          src={thumbLogo}
        />
      ) : (
        <div className="react-chatbot-kit-chat-bot-avatar-blank" />
      )}
    </div>
  );
};

export default Avatar;
