import { useState, useEffect, useRef, MutableRefObject } from "react";
import {
  createClientMessage,
  createChatBotMessage,
  createCustomMessage,
} from "../components/Chat/chatUtils.ts";
import {
  getInitialState,
  getWidgets,
  isConstructor,
  validateProps,
} from "../components/Chatbot/utils.ts";
import WidgetRegistry from "../components/WidgetRegistry/WidgetRegistry.js";
import IConfig from "../interfaces/IConfig.ts";
import { IMessage } from "../interfaces/IMessages.ts";
import IWidget from "../interfaces/IWidget.ts";

interface IUseChatbotParams {
  config: IConfig;
  actionProvider: any;
  messageParser: any;
  messageHistory: IMessage[] | string;
  saveMessages: (messages: IMessage[], html: string) => any | null;
  runInitialMessagesWithHistory?: boolean;
}

const useChatbot = ({
  config,
  actionProvider,
  messageParser,
  messageHistory,
  runInitialMessagesWithHistory,
  saveMessages,
  ...rest
}: IUseChatbotParams) => {
  const initialState = getInitialState(config);

  if (messageHistory && Array.isArray(messageHistory)) {
    config.initialMessages = [...messageHistory];
  } else if (typeof messageHistory === "string" && Boolean(messageHistory)) {
    if (!runInitialMessagesWithHistory) {
      config.initialMessages = [];
    }
  }

  const [state, setState] = useState({
    messages: [...config.initialMessages],
    ...initialState,
  });
  const messagesRef = useRef(state.messages);
  const stateRef = useRef();
  const messageContainerRef: MutableRefObject<HTMLDivElement> = useRef();

  useEffect(() => {
    messagesRef.current = state.messages;
  });

  useEffect(() => {
    if (messageHistory && Array.isArray(messageHistory)) {
      setState((prevState: any) => ({
        ...prevState,
        messages: messageHistory,
      }));
    }
  }, [messageHistory]);

  useEffect(() => {
    const refValue: HTMLDivElement = messageContainerRef.current;

    return () => {
      if (saveMessages && typeof saveMessages === "function") {
        const HTML = refValue.innerHTML.toString();

        saveMessages(messagesRef.current, HTML);
      }
    };
  }, [saveMessages]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  let actionProv;
  let widgetRegistry: WidgetRegistry;
  let messagePars;
  let widgets;

  const ActionProvider = actionProvider;
  const MessageParser = messageParser;

  if (isConstructor(ActionProvider) && isConstructor(MessageParser)) {
    actionProv = new actionProvider(
      createChatBotMessage,
      setState,
      createClientMessage,
      stateRef.current,
      createCustomMessage,
      rest,
    );

    widgetRegistry = new WidgetRegistry(setState, actionProv);
    messagePars = new messageParser(actionProv, stateRef.current);

    widgets = getWidgets(config);
    widgets.forEach((widget: IWidget) =>
      widgetRegistry?.addWidget(widget, rest),
    );
  } else {
    actionProv = actionProvider;
    messagePars = messageParser;
    widgetRegistry = new WidgetRegistry(setState, null);

    widgets = getWidgets(config);
    widgets.forEach((widget: IWidget) =>
      widgetRegistry?.addWidget(widget, rest),
    );
  }

  let invalidPropsError = "";
  const propsErrors = validateProps(config, messageParser);

  if (propsErrors.length) {
    invalidPropsError = propsErrors.reduce((prev, cur) => {
      prev += cur;
      return prev;
    }, "");

    return { invalidPropsError };
  }

  let configurationError = "";
  if (!config || !actionProvider || !messageParser) {
    configurationError =
      "I think you forgot to feed me some props. Did you remember to pass a config, a messageparser and an actionprovider?";

    return { configurationError };
  }

  return {
    widgetRegistry,
    actionProv,
    messagePars,
    configurationError,
    invalidPropsError,
    state,
    setState,
    messageContainerRef,
    ActionProvider,
    MessageParser,
  };
};

export default useChatbot;
