import React, { useState } from "react";
import Chatbot, { createCustomMessage } from "./react-chatbot-kit/index.ts";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import config from "./chatbot/Config";
import ActionProvider from "./chatbot/ActionProvider";
import Avatar from "./chatbot/Avatar.js";
import ChatbotCloseButton from "./assets/chatbot-close.svg";
import ChatbotOpenButton from "./assets/chatbot-open.svg";
import MessageParser from "./chatbot/MessageParser";
import Message from "./chatbot/Message";

function App({ chatbotJson }) {
  document.title = chatbotJson.title;

  config.initialMessages = [
    createCustomMessage(chatbotJson.initialMessage, "custom"),
  ];

  config.customStyles = {
    chatButton: {
      backgroundColor: chatbotJson.sendButtonColor,
    },
  };

  const customAvatar = (props) => {
    const newProps = { thumbLogo: chatbotJson.thumbLogo, ...props };
    return <Avatar {...newProps} />;
  };

  config.customMessages = {
    custom: (props) => (
      <Message
        {...props}
        message={props.state.messages.find(
          (msg) => msg.payload === props.payload,
        )}
        customAvatar={customAvatar}
      />
    ),
  };

  const [showChatbot, setShowChatbot] = useState(chatbotJson.openOnLoad);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div className="App">
      <header className="App-header">
        {showChatbot && (
          <Fade big>
            <div className="card">
              <div className="chatbot-close-button" onClick={toggleChatbot}>
                <ChatbotCloseButton />
              </div>
              <Chatbot
                headerText={chatbotJson.title}
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider(chatbotJson)}
              />
            </div>
          </Fade>
        )}
        <Flip left cascade>
          <button className="app-chatbot-button" onClick={toggleChatbot}>
            {showChatbot ? (
              <ChatbotCloseButton className="close-chatbot-icon" />
            ) : (
              <ChatbotOpenButton />
            )}
          </button>
        </Flip>
      </header>
    </div>
  );
}

export default App;
