import React from "react";
import SuggestedOption from "./SuggestedOption";

const config = {
  botName: "Simply Binary",
  widgets: [
    {
      widgetName: "SuggestedOption",
      widgetFunc: (props) => <SuggestedOption {...props} />,
    },
  ],
  state: {},
};

export default config;
