import React, { Children, cloneElement } from "react";

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    actions.handleMessage(message.toLowerCase());
  };

  return (
    <div>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
