import React from "react";
import Linkify from "react-linkify";

const Message = ({ message, customAvatar }) => {
  return (
    <div className="react-chatbot-kit-chat-bot-message-container">
      {customAvatar()}

      <div className="react-chatbot-kit-chat-bot-message">
        <span>
          <Linkify>{message.message}</Linkify>
        </span>
        <div className="react-chatbot-kit-chat-bot-message-arrow" />
      </div>
    </div>
  );
};

export default Message;
